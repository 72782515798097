import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import blankExperienceTemplateCover from "@assets/images/covers/blank-experience-template-cover.png"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import classNames from "classnames"

interface Props {
  testid: string
  title: string
  description: string
  cover?: string
  onClick(): void
  isSelected?: boolean
  tooltip?: string
  isRecommended?: boolean
  disabled?: boolean
  svg?: React.ReactNode
}

function DiscoTemplateCardButton(props: Props) {
  const {
    title,
    description,
    testid,
    onClick,
    tooltip,
    isSelected = false,
    isRecommended = false,
    disabled = false,
    svg,
  } = props

  let { cover } = props
  if (!cover && !svg) {
    cover = blankExperienceTemplateCover
  }

  const classes = useStyles({ disabled })

  return (
    <DiscoTooltip disabled={!tooltip} content={tooltip}>
      <span>
        <DiscoButton
          color={"transparent"}
          testid={testid}
          onClick={onClick}
          className={classNames(classes.root, { [classes.selectedRoot]: isSelected })}
          disabled={disabled}
          style={disabled ? { pointerEvents: "none" } : {}}
        >
          <div className={classes.title}>
            <div className={classes.icon}>{svg}</div>

            <DiscoText variant={"body-md-600"}>{title}</DiscoText>
          </div>
          <DiscoText variant={"body-xs"} className={classes.description} component={"p"}>
            {description}
          </DiscoText>
          {isRecommended && (
            <div className={classes.recommendedTag}>
              <DiscoIcon className={classes.recommendedTagIcon} icon={"check-circle"} />
              {"Recommended"}
            </div>
          )}
        </DiscoButton>
      </span>
    </DiscoTooltip>
  )
}

type StyleProps = {
  disabled: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ disabled }: StyleProps) => ({
    backgroundColor: "transparent !important",
    height: "108px",
    position: "relative",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    textAlign: "left",
    width: "100%",

    ...styleIf(disabled, {
      opacity: 0.35,
    }),

    ...styleIf(!disabled, {
      "&:hover": {
        boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
      },
    }),
  }),
  selectedRoot: {
    border: `2px solid ${theme.palette.primary.main} !important`,
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  description: {
    // Override specificity of the button
    color: `${theme.palette.text.secondary} !important`,
    marginTop: theme.spacing(1.5),
  },
  recommendedTag: {
    display: "flex",
    position: "absolute",
    fontWeight: 600,
    borderRadius: theme.measure.borderRadius.default,
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    gap: "3px",
    height: "22px",
    right: theme.spacing(1),
    top: theme.spacing(1.25),
    fontSize: "11px",
    boxShadow: theme.palette.groovyDepths.insideCard,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  icon: {
    "& > svg": {
      width: "20px",
      height: "20px",
      stopColor:
        theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },

    width: "32px",
    height: "32px",
    backgroundColor: theme.palette.groovy.neutral[300],
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.smedium,
  },
  recommendedTagIcon: {
    color: theme.palette.primary.main,
    fill: "white",
    width: "15px",
  },
}))

export default DiscoTemplateCardButton
